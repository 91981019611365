<template>
    <v-row no-gutters>
        <v-col cols="12">
            <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-alert type="warning" v-if="!user.is_registered_loginshield && !user.is_registered_loginfront">
                        Your profile is incomplete. <router-link :to="{ name: 'profile-setup' }" class="white--text">Set up your profile.</router-link>
                    </v-alert>
                    <v-card elevation="2" class="my-6">
                        <v-app-bar color="green darken-2" dark flat dense>
                            <v-app-bar-title>Dashboard</v-app-bar-title>
                            <!-- <v-spacer/>
                            <v-btn icon @click="openCreateOrganizationDialog">
                                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width></font-awesome-icon>
                            </v-btn> -->
                        </v-app-bar>
                        <v-card-text>
                            <p class="text-overline mb-0 mt-5">Profile</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'profile' }">My profile</router-link>
                            </p>
                            <p class="text-overline mb-0 mt-10">Organizations</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'user-organization-list' }">Manage organizations</router-link>
                            </p>
                            <template v-if="isPermitServiceAdmin">
                            <p class="text-overline mb-0 mt-10">Service Administration <font-awesome-icon :icon="['fas', 'id-badge']" class="green--text"></font-awesome-icon></p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'service-admin' }">Manage Unicorn Springs</router-link>
                            </p>
                            </template>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { mapState /* , mapGetters */ } from 'vuex';
import { isValidName, isValidEmail, isValidPhone } from '@/sdk/input';

export default {
    data: () => ({
        snackbarRememberDevice: false,
        snackbarLockTimeUpdated: false,
        snackbarOrganizationDeleteFailed: false,
        dialogDeleteOrganization: false,
        // nameRules: [
        //     (v) => (typeof v === 'string' && v.length >= 1) || 'Must not be empty',
        // ],
        nameRules: [
            (v) => isValidName(v) || 'Enter your name',
        ],
        emailRules: [
            (v) => !v || isValidEmail(v) || 'Enter your email address',
        ],
        phoneRules: [
            (v) => !v || isValidPhone(v) || 'Enter your phone number',
        ],
        snackbarIdentityNameUpdated: false,
        snackbarIdentityEmailUpdated: false,
        dialogEditDisplayName: false,
        editableDisplayName: null,
        editableEmail: null,
    }),
    computed: {
        ...mapState({
            user: (state) => state.user,
            session: (state) => state.session,
            // organization: (state) => state.organization,
        }),
        isViewReady() {
            return this.session.isAuthenticated && this.user;
        },
        displayName() {
            if (this.user.info && this.user.info.displayName) {
                return this.user.info.displayName;
            }
            return '(unknown)';
        },
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        // name: {
        //     get() {
        //         if (this.user.name) {
        //             return this.user.name;
        //         }
        //         return '';
        //     },
        //     set(value) {
        //         this.$store.dispatch('editUser', { name: value });
        //     },
        // },
        // email: {
        //     get() {
        //         if (this.user.email) {
        //             return this.user.email;
        //         }
        //         return '';
        //     },
        //     set(value) {
        //         // TODO: need email verification first ... let server set it after user clicks link ... server should reject editUser { email } , it should make available a workflow for changing email that a client can initiate
        //         this.$store.dispatch('editUser', { email: value });
        //     },
        // },
        isEditDisplayNameFormComplete() {
            return this.user
            && this.editableDisplayName
            && this.editableDisplayName !== this.user.info.displayName;
        },
        isIdentityNameComplete() {
            return this.user
            && this.editableDisplayName
            && this.editableDisplayName !== this.user.name;
        },
        isIdentityEmailComplete() {
            return this.user
            && this.editableEmail
            && this.editableEmail !== this.user.email;
        },
    },
    watch: {
        dialogEditDisplayName(display) {
            if (display) {
                this.editableDisplayName = this.user.info.displayName;
            }
        },
        email(val) {
            this.editableEmail = val;
        },
        dialogDeleteOrganization(val) {
            if (!val) {
                this.closeDialogDeleteOrganization();
            }
        },
        isViewReady(newValue, oldValue) {
            if (newValue && !oldValue) {
                this.immediateRedirect();
            }
        },
    },
    methods: {
        immediateRedirect() {
            // NOTE: temporary redirect to account list until there's more content to show in the dashboard like notifications, charts/graphs, search through all accounts, etc.
            // TODO: remove this redirect when we have more content to show in the dashboard;  the check for profile-setup is only because we're redirecting to account list immediately; if user is staying on dashboard, there's an alert for the account setup
            if (!this.user.is_registered_loginshield && !this.user.is_registered_loginfront) {
                this.$router.push({ name: 'profile-setup' });
            } else {
                this.$router.replace({ name: 'user-organization-list' });
            }
        },
        async editDisplayName() {
            // try {
            //     this.$store.commit('loading', { editDisplayName: true });
            const isEdited = await this.$store.dispatch('editCurrentUser', { displayName: this.editableDisplayName });
            // this.name = this.editableDisplayName;
            // this.snackbarIdentityNameUpdated = true;
            if (isEdited) {
                this.dialogEditDisplayName = false;
                this.$bus.$emit('snackbar', { type: 'success', message: 'Updated name' });
            } else {
                this.$bus.$emit('snackbar', { type: 'error', message: 'Failed to update name' });
            }
            // } catch (err) {
            //     console.error('editDisplayName failed', err);
            //     this.$bus.$emit('snackbar', { type: 'error', message: 'Failed to update name' });
            // } finally {
            //     this.$store.commit('loading', { editDisplayName: false });
            // }
        },
        // editEmail() {
        //     this.email = this.editableEmail;
        //     this.snackbarIdentityEmailUpdated = true;
        // },
        async deleteOrganization() {
            this.$store.commit('loading', { deleteOrganization: true });
            const response = await this.$client.user(this.user.id).user.delete();
            if (response.isDeleted) {
                await this.$store.dispatch('logout');
                this.$router.push('/');
                console.log('organization.vue: deleteOrganization success: %o', response);
            } else {
                console.log('organization.vue: deleteOrganization failed: %o', response);
                this.snackbarOrganizationDeleteFailed = true;
            }
            this.closeDialogDeleteOrganization();
            this.$store.commit('loading', { deleteOrganization: false });
        },
        closeDialogDeleteOrganization() {
            this.dialogDeleteOrganization = false;
        },
    },
    mounted() {
        // when user is looking at their dashboard, they are in between organizations so de-select whatever may have been selected
        this.$store.commit('organization', null);
        if (this.isViewReady) {
            this.immediateRedirect(); // TODO: remove this temporary redirect when there's enough content for the dashboard that it makes sense to restore the dashbaord as the user's first view after signing in
        }
    },
};
</script>
